* {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  overflow: hidden;
}

body {
  background-image: url("low_contrast_linen.e2813a11.png");
  background-repeat: repeat;
  font-family: Helvetica, sans-serif;
}

iframe {
  height: 100%;
  width: 100%;
  border: 0;
}

.contents {
  height: 100%;
  width: 100%;
}

.applications-button {
  color: #fff;
  text-align: center;
  z-index: 900;
  background-color: #000;
  border-top: 3px solid gray;
  border-left: 3px solid gray;
  border-right: 3px solid gray;
  padding: 5px 10px 0;
  font-size: 20px;
  font-weight: 700;
  transition: all .5s ease-in-out;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.meny-active .applications-button {
  transition: all .5s ease-in-out;
  transform: translateX(-50%)translateY(-150px);
}

.applications-tray {
  color: #fff;
  height: 150px;
  width: 100%;
  background: #000;
  border-top: 3px solid gray;
}

.applications-tray > div {
  height: 100%;
  width: 100%;
  justify-content: stretch;
  align-items: center;
  display: flex;
}

.applications-tray-item {
  text-align: center;
  flex: 1 1 0;
}

.applications-tray-item a {
  color: #fff;
  text-decoration: none;
}

/*# sourceMappingURL=index.a0a8c01a.css.map */
