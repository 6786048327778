* {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  overflow: hidden;
}

body {
  background-image: url('../images/low_contrast_linen.png');
  background-repeat: repeat;
  font-family: Helvetica, sans-serif;
}

iframe {
  border: 0;
  height: 100%;
  width: 100%;
}

.contents {
  height: 100%;
  width: 100%;
}

.applications-button {
  background-color: black;
  border-left: solid 3px grey;
  border-right: solid 3px grey;
  border-top: solid 3px grey;
  bottom: 0px;
  color: white;
  font-size: 20px;
  font-weight: 700;
  left: 50%;
  padding: 5px 10px 0px;
  position: fixed;
  text-align: center;
  transform: translateX(-50%);
  z-index: 900;
  transition: all 0.5s ease-in-out;
}

.meny-active .applications-button {
  transform: translateX(-50%) translateY(-150px);
  transition: all 0.5s ease-in-out;
}

.applications-tray {
  background: black;
  border-top: solid 3px grey;
  color: white;
  height: 150px;
  width: 100%;
}

.applications-tray > div {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: stretch;
  width: 100%;
}

.applications-tray-item {
  flex: 1 1 0;
  text-align: center;
}

.applications-tray-item a {
  color: white;
  text-decoration: none;
}
